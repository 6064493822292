import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FeaturedCasesStyled from '@/app/case/FeaturedCasesStyled';
import { BaseComponentProps, HomeFeaturedCasesQuery } from '@/types';
import Reveal from '@/components/reveal/Reveal';

const query = graphql`
  query homeCase {
    allContentfulCase(
      filter: {
        metadata: {
          tags: { elemMatch: { name: { in: "home-featured-case" } } }
        }
      }
    ) {
      nodes {
        slug
        headline
        projectName
        featuredImageAlt
        featuredImageMobile {
          title
          gatsbyImageData
        }
      }
    }
  }
`;

const CaseSection: React.FC<BaseComponentProps> = () => {
  const { allContentfulCase: data }: HomeFeaturedCasesQuery =
    useStaticQuery<HomeFeaturedCasesQuery>(query);

  const cases = (value) => (
    <>
      <a href={`/work/${value.slug}`} arial-label={`${value.projectName}`}>
        <div className="details">
          <div className="image-container">
            <GatsbyImage
              image={getImage(value.featuredImageMobile.gatsbyImageData)}
              alt={`${value.projectName}`}
            />
          </div>
          <h2>{value.headline}</h2>
        </div>
      </a>

      <div className="action">
        <Link
          className="link"
          to={`/work/${value.slug}`}
          aria-label={`${value.projectName}`}
        >
          Case {value.projectName}
        </Link>
      </div>
    </>
  );

  const renderCases = () =>
    data.nodes.map((value, i) => {
      const index = i + 1;
      const workThirdCase = 3;
      if (index < workThirdCase) {
        return (
          <Reveal key={value.slug} className="case card">
            {cases(value)}
          </Reveal>
        );
      }

      return (
        <Reveal key={value.slug} className="case card">
          {cases(value)}
        </Reveal>
      );
    });

  return (
    <FeaturedCasesStyled className="featured-cases">
      <Reveal className="section-header container">
        <h2 className="mini-header">
          Featured cases
          <img src="/assets/images/arrow-down.svg" alt="" />
        </h2>
      </Reveal>

      <div className="cases-container container">
        <div className="cases">{renderCases()}</div>
      </div>
    </FeaturedCasesStyled>
  );
};

export default CaseSection;
